import React, {Component} from 'react';
import Layout from './hoc/Layout/Layout';
import Auth from './containers/Auth/Auth';
import './App.css';
import {Route, Switch, withRouter, BrowserRouter} from 'react-router-dom';
import {connect} from "react-redux";
import * as actions from './store/actions/index';
import asyncComponent from './hoc/asyncComponent/asynComponent';
import PinVerification from './containers/ParentZone/PinVerification/PinVerification';
import ManageVideos from "./containers/ParentZone/KidProfile/ManageContentAccess/Video/ManageVideos";
import ManageStories from "./containers/ParentZone/KidProfile/ManageContentAccess/Stories/ManageStories";
import Downloads from "./containers/ParentZone/Settings/Downloads/Downloads";
import logger from "./utils/logger";
import SplashScreenRed from "./components/UI/SplashScreenRed/SplashScreenRed";

const ParentZone = asyncComponent(() => {
    return import('./containers/ParentZone/ParentZone');
});

const PinCreation = asyncComponent(() => {
    return import('./containers/Auth/PinCreation/PinCreation');
});
const CreateKidProfile = asyncComponent(() => {
    return import('./containers/ParentZone/KidProfile/CreateKidProfile/CreateKidProfile');
});
const Settings = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/Settings');
});

const ContentUsage = asyncComponent(() => {
    return import('./containers/ParentZone/KidProfile/ContentUsage/ContentUsage');
});
const SubscriptionInfo = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/SubscriptionInfo/SubscriptionInfo');
});
const SelectLanguage = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/SelectLanguage/SelectLanguage');
});
const ChangePin = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/ChangePin/ChangePin');
});

const Help = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/Help/Help');
});

const Info = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/Info/Info');
});
const SfrApps = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/SfrApps/SfrApps');
});
const ManageGamesAccess = asyncComponent(() => {
    return import('./containers/ParentZone/KidProfile/ManageContentAccess/Games/ManageGames');
});
const ManageGameCategoriesAccess = asyncComponent(() => {
    return import('./containers/ParentZone/KidProfile/ManageContentAccess/Games/ManageCategories');
});
const KidZoneVideo = asyncComponent(() => {
    return import('./containers/KidZone/Videos/Videos');
});
const KidZoneComptines = asyncComponent(() => {
    return import('./containers/KidZone/Comptines/Comptines');
});

const KidZoneGames = asyncComponent(() => {
    return import('./containers/KidZone/Games/Games');
});
const KidZoneFavorites = asyncComponent(() => {
    return import('./containers/KidZone/Favorites/Favorites');
});
const KidZoneFeatures = asyncComponent(() => {
    return import('./containers/KidZone/Features/Features');
});

const KidZoneStories = asyncComponent(() => {
    return import('./containers/KidZone/Stories/Stories');
});

const Tutorial = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/Help/Tutorial/Tutorial');
});

const Contact = asyncComponent(() => {
    return import('./containers/ParentZone/Settings/Contact/Contact');
});

let basepath = '';

if(window.location.hostname === 'ludigames.gameloft.com') {
    //basepath = '/fr/sfr_fr_kids_webapp_staging/';
    basepath = '/';
}
class App extends Component {

    UNSAFE_componentWillMount() {
        logger.debug('App js componentDidMount',this.props);
        this.props.onTryToAutoSignup();
    }

    render() {
        let routes = (
            <Switch>
                <Route path="/help/:qId/:subId" exact component={Help}/>
                <Route path="/" component={Auth}/>
                <Route path="/logout" component={Auth}/>
            </Switch>
        );
        if (this.props.isAuthenticated) {
            routes = (
                <BrowserRouter basename={basepath}>
                    <Route path="/kids" exact component={ParentZone}/>
                    <Route path="/pinCreation" exact component={PinCreation}/>
                    <Route path="/createKidProfile" exact component={CreateKidProfile}/>
                    <Route path="/pinVerification" exact component={PinVerification}/>
                    <Route path="/settings" exact component={Settings}/>
                    <Route path="/downloads" exact component={Downloads}/>
                    <Route path="/contentUsage" exact component={ContentUsage}/>
                    <Route path="/subInfo" exact component={SubscriptionInfo}/>
                    <Route path="/changePin" exact component={ChangePin}/>
                    <Route path="/selectLanguage" exact component={SelectLanguage}/>
                    <Route path="/tutorial" exact component={Tutorial}/>
                    <Route path="/contact" exact component={Contact}/>
                    <Route path="/help" exact component={Help}/>
                    <Route path="/help/:qId" exact component={Help}/>
                    <Route path="/help/:qId/:subId" exact component={Help}/>
                    <Route path="/info" exact component={Info}/>
                    <Route path="/info/:qId" exact component={Info}/>
                    <Route path="/stories" exact component={KidZoneStories}/>
                    <Route path="/features" exact component={KidZoneFeatures}/>
                    <Route path="/favorites" exact component={KidZoneFavorites}/>
                    <Route path="/jeux" exact component={KidZoneGames}/>
                    <Route path="/comptines" exact component={KidZoneComptines}/>
                    <Route path="/videos" exact component={KidZoneVideo}/>
                    <Route path="/sfrApps" exact component={SfrApps}/>
                    <Route path="/contentAccess/gameCategories" exact component={ManageGameCategoriesAccess}/>
                    <Route path="/contentAccess/Videos" exact component={ManageVideos}/>
                    <Route path="/contentAccess/Stories" exact component={ManageStories}/>
                    <Route path="/contentAccess/:catId/games" exact component={ManageGamesAccess}/>
                    <Route path="/" exact component={ParentZone}/>
                </BrowserRouter>
            );
        }
        return (
            <Layout>
                <SplashScreenRed />
                {routes}
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onTryToAutoSignup: () => dispatch(actions.authCheckState()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

import React, {Component, Fragment} from 'react';
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import styles from '../../../ParentZone.module.css';
import Checkbox from '../../../../../components/UI/Checkbox/Checkbox';
import TopBar from "../../../../../components/Navigation/TopBar/TopBar";
import {text} from "../../../../../utils/translator";
import noGameIcon from "../../../../../assets/images/placeholder-video.svg";
import {filterContentList} from "../../../../../utils/kidZoneManager";
import logger from "../../../../../utils/logger";

class ManageVideos extends Component {
    state = {
        pageTitle: '',
    };

    componentDidMount() {
        logger.debug('ManageVideos.js componentDidMount',this.props,this.state);
        window.scrollTo(0, 0);
        let title = text('empty_content_access');
        if(this.props.kidProfile && this.props.kidProfile.name){
            title = text('kids_content_access', this.props.kidProfile.name);
        }
        this.props.getSection('video_list');
        this.setState({pageTitle: title})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.videoList && prevProps.videoList !== this.props.videoList) {
            this.props.videoList.sub_sections.forEach(subsection => {
                let section = this.props.subsections.find(section => section.identifier === subsection.identifier);

                if (section === undefined) {
                    this.props.getSection(subsection.identifier);
                }
            })
        }
    }

    videoAccessChange = (pId) => {
        let updatedBlockedGames = this.props.kidProfile.videos.blocked;
        let inList = false;
        if(updatedBlockedGames !== undefined){
            this.props.kidProfile.videos.blocked.forEach((gameId, index) => {
                if(gameId === pId){
                    inList = true;
                    updatedBlockedGames.splice(index, 1);
                }
            });
        } else {
            updatedBlockedGames = [];
        }

        if(!inList){
            updatedBlockedGames.push(pId);
        }

        let updatedProfile = {
            ...this.props.kidProfile,
            videos: {
                ...this.props.kidProfile.videos,
                blocked: updatedBlockedGames
            }
        };

        this.props.onProfileUpdate(updatedProfile, this.props.updateProfileId);
    };

    getCheckBoxStatus = (pId) => {
        if(this.props.kidProfile !== null && this.props.kidProfile.videos.blocked.length === 0){
            return "checked"
        }
        let status = "checked";
        this.props.kidProfile.videos.blocked.forEach(videoId => {
            if(videoId === pId){
                status = "unchecked"
            }
        });
        return status;
    };

    render() {
        logger.debug('ManageVideos.js',this.state,this.props);
        
        let filteredSections = [];
        let uniqueIdentifiers = [];

        if(this.props.videoList){
            if(this.props.subsections.length > 0) {
                filteredSections = this.props.subsections.map( subsection => {
                    let videosFiltered = subsection.contents.map(element => {
                        return this.props.videos.find(video => {
                            let idFromList = parseInt(video.id);
                            let idFromSection = parseInt(element.id);
                            if (isNaN(idFromList) || isNaN(idFromSection)) {
                                return false
                            }
                            return idFromList === idFromSection;
                        })
                    });
                    videosFiltered = filterContentList(videosFiltered, this.props.kidProfile, -1, 'videos');
                    if(videosFiltered.length > 0 && !uniqueIdentifiers.includes(subsection.identifier)) {
                        let titleStyle = null;

                        if(subsection.title.length > 30){
                            titleStyle = {lineHeight: '20px', marginTop: '10px'};
                        }
                        uniqueIdentifiers.push(subsection.identifier);
                        return <div className={styles.SettingsLine} key={subsection.identifier}>
                            <img src={subsection.cover ? subsection.cover.replace(/http/, 'https') : noGameIcon}
                                 className={styles.AccessControlsGameImage}
                                 alt={subsection.title}/>
                            <div className={styles.AccessControls} style={titleStyle}>{subsection.title}</div>
                            <Checkbox
                                checkBoxStatus={this.getCheckBoxStatus(subsection.identifier)}
                                onChangeHandler={(id) => this.videoAccessChange(id)}
                                elementId={subsection.identifier}
                            />
                        </div>
                    }
                    return null;
                });
            }
        }

        return (
            <Fragment>
                <TopBar pageTitle={this.state.pageTitle} backToHandler={(backTo) => this.props.history.push(backTo)} hasHomeButton={true} backTo={"/createKidProfile"}/>
                <div className={styles.SectionTitleWithImage} >
                    <span className={styles.AccessControlsImage +' icons icon-videos'} alt='videos'></span>
                    {text('Videos')}</div>
                {filteredSections}
            </Fragment>
        );
    };

}

const mapStateToProps = state => {
    return {
        updateProfileId: state.parentZone.updateProfileId,
        kidProfile: state.parentZone.kidProfile,
        loading: state.kidZone.loading,
        error: state.kidZone.error,
        videos: state.kidZone.videos,
        videoList: state.kidZone.videoList,
        subsections: state.kidZone.subsections,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onProfileUpdate: (profile, updateProfileId) => dispatch(actions.updateKidProfile(profile, updateProfileId)),
        onEditKidProfile: (profile, id) => dispatch(actions.updateKidProfile(profile, id)),
        getVideos: () => dispatch(actions.getVideos()),
        getSection: (section) => dispatch(actions.getSection(section))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageVideos);
import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as axiosHelper from "./axiosHelper";
import {IGP_CODE, KIDS_BACK_PROD} from "./axiosHelper";
import {getAppLanguage} from "../../utils/translator";
import logger from "../../utils/logger";
import {isStoriesEnabled} from "../../utils/IOS";

export const apiCallStart = () => {
    return {
        type: actionTypes.API_CALL_START,
    }
};
export const getPlayLinkStart = () => {
    return {
        type: actionTypes.GET_PLAY_LINK_START,
    }
};

export const apiCallFailed = (error) => {
    return {
        type: actionTypes.API_CALL_FAILED,
        error: error
    }
};
export const getKidsProfileSuccess = (kidsProfiles, kidId) => {
    return {
        type: actionTypes.GET_KID_PROFILE_SUCCESS,
        kidProfile: kidsProfiles[kidId] ? kidsProfiles[kidId] : [],
    }
};
export const getGamesSuccess = (games) => {
    return {
        type: actionTypes.GET_GAMES_SUCCESS,
        games: games
    }
};
export const getVideosSuccess = (videos) => {
    return {
        type: actionTypes.GET_VIDEOS_SUCCESS,
        videos: videos
    }
};

export const getStoriesSuccess = (stories) => {
    return {
        type: actionTypes.GET_STORIES_SUCCESS,
        stories: stories
    }
};
export const getStorySrcStart = () => {
    return {
        type: actionTypes.GET_STORY_SRC_START,
    }
};
export const getStorySrcSuccess = (video) => {
    return {
        type: actionTypes.GET_STORY_SRC_SUCCESS,
        storySrc: video
    }
};
export const getPlayLinkSuccess = (gameLink) => {
    logger.debug('getPlayLinkSuccess',gameLink);
    return {
        type: actionTypes.GET_PLAY_LINK_SUCCESS,
        gameLink: gameLink
    }
};
export const getVideoSrcStart = () => {
    return {
        type: actionTypes.GET_VIDEO_SRC_START,
    }
};
export const getVideoSrcSuccess = (video) => {
    return {
        type: actionTypes.GET_VIDEO_SRC_SUCCESS,
        videoSrc: video
    }
};
export const getFeaturesSuccess = (features) => {
    return {
        type: actionTypes.GET_FEATURES_SUCCESS,
        features: features
    }
};
export const getSectionStart = (identifier) => {
    return {
        type: actionTypes.GET_SUBSECTION_START,
        subsectionId: identifier
    }
};
export const getSectionSuccess = (subsection) => {
    return {
        type: actionTypes.GET_SUBSECTION_SUCCESS,
        subsection: subsection
    }
};
export const getVideoListSuccess = (subsection) => {
    return {
        type: actionTypes.GET_VIDEO_LIST_SUCCESS,
        videoList: subsection
    }
};
export const getAudioListSuccess = (subsection) => {
    return {
        type: actionTypes.GET_STORIES_LIST_SUCCESS,
        storyList: subsection
    }
};
export const refreshKidProfile = (kidId) => {
    logger.debug('refreshKidProfile');
    return dispatch => {
        dispatch(apiCallStart());
        axiosHelper.getMyProfile(true).then(profileInfo => {
            dispatch(getKidsProfileSuccess(profileInfo.kids, kidId));
        }).catch(error => {
            dispatch(apiCallFailed(error));
        });

    };
};
export const kidZoneInit = (kidId) => {
    return dispatch => {
        dispatch(apiCallStart());
        axiosHelper.getMyProfile().then(profileInfo => {
            dispatch(getKidsProfileSuccess(profileInfo.kids, kidId));
        }).catch(error => {
            dispatch(apiCallFailed(error));
        });

    };
};
export const getGames = () => {
    return dispatch => {
        dispatch(apiCallStart());
        const expirationDate = new Date(localStorage.getItem('gameListExpirationDate'));
        let gameList = JSON.parse(localStorage.getItem('gameList'));
        if (expirationDate > new Date() && gameList.length > 0) {
            dispatch(getGamesSuccess(gameList));
        } else {
            axios.get(KIDS_BACK_PROD + '/games?igp=' + IGP_CODE,
                {
                    headers: {
                        'Accept': 'application/vnd.gameloft.kids-v2.0.0+json',
                        'Accept-Language': getAppLanguage()
                    }
                }
            ).then(response => {
                logger.debug(response);
                if (response.data.length > 0) {
                    localStorage.setItem('gameList', JSON.stringify(response.data));
                    localStorage.setItem('gameListExpirationDate', new Date(new Date().getTime() + 60 * 60 * 1000).toGMTString());
                }

                dispatch(getGamesSuccess(response.data));
            }).catch(error => {
                logger.debug(error);
                dispatch(apiCallFailed(error.error));
            });
        }
    };
};
export const getVideos = () => {
    return dispatch => {
        dispatch(apiCallStart());
        axios.get(KIDS_BACK_PROD + '/' + IGP_CODE + '/videos',
            {
                headers: {
                    'Accept': 'application/vnd.gameloft.kids-v2.0.0+json',
                    'Accept-Language': getAppLanguage()
                }
            }
        ).then(response => {
            logger.debug(response);
            dispatch(getVideosSuccess(response.data));
        }).catch(error => {
            logger.debug(error);
            dispatch(apiCallFailed(error.error));
        });
    };
};
export const getVideoSrc = (videoId,linkId) => {
    return dispatch => {
        dispatch(getVideoSrcStart());
        let clientId = localStorage.getItem('client_id');
        axios.get(KIDS_BACK_PROD + '/' + IGP_CODE + '/videos/' + videoId + '?client_id=' + clientId +'&link_id='+linkId,
            {headers: {Accept: "application/vnd.gameloft.kids-v2.0.0+json"}}
        ).then(response => {
            logger.debug(response);
            dispatch(getVideoSrcSuccess(response.data));
        }).catch(error => {
            logger.debug(error);
            dispatch(apiCallFailed(error.error));
        });
    };
};
export const getPlayLink = (gameId) => {
    return dispatch => {
        dispatch(getPlayLinkStart());
        let clientId = localStorage.getItem('client_id');
        axios.get(KIDS_BACK_PROD + '/download.php?igp=' + IGP_CODE + '&game=' + gameId + '&client_id=' + clientId + '&sw=1'
        ).then(response => {
            logger.debug('getPlayLink',response);
            dispatch(getPlayLinkSuccess(response.data));
        }).catch(error => {
            logger.debug(error);
            dispatch(apiCallFailed(error.error));
        });
    };
};
export const getSection = (section) => {
    return dispatch => {
        dispatch(getSectionStart(section));
        axios.get(KIDS_BACK_PROD + '/' + IGP_CODE + '/sections/' + section,
            {
                headers: {
                    'Accept': 'application/vnd.gameloft.kids-v2.0.0+json',
                    'Accept-Language': getAppLanguage()
                }
            }
        ).then(response => {
            logger.debug(response);
            if (section === 'featured' || section === 'featured_v2') {
                dispatch(getFeaturesSuccess(response.data));
            } else if (section === 'video_list') {
                dispatch(getVideoListSuccess(response.data));
            } else if (section === 'audio_list') {
                dispatch(getAudioListSuccess(response.data));
            } else {
                if(!response.data.identifier) {
                    dispatch(getSectionSuccess({...response.data, identifier: section}));
                } else {
                    dispatch(getSectionSuccess(response.data));
                }
            }
        }).catch(error => {
            logger.debug(error);
            dispatch(apiCallFailed(error.error));
        });
    };
};


export const flashMessageReset = () => {
    return {
        type: actionTypes.FLASH_MESSAGE_RESET
    }
};

export const flashMessageShown = () => {
    return {
        type: actionTypes.FLASH_MESSAGE_SHOWN
    }
};

export const getStories = () => {
    return dispatch => {
        dispatch(apiCallStart());
        if(!isStoriesEnabled()) {
            dispatch(getStoriesSuccess([]));
        } else {
            axios.get(KIDS_BACK_PROD + '/' + IGP_CODE + '/audios',
                {
                    headers: {
                        'Accept': 'application/vnd.gameloft.kids-v2.0.0+json',
                        'Accept-Language': getAppLanguage()
                    }
                }
            ).then(response => {
                logger.debug(response);
                dispatch(getStoriesSuccess(response.data));
            }).catch(error => {
                logger.debug(error);
                dispatch(apiCallFailed(error.error));
            });
        }
    };
};
export const getStorySrc = (storyId, linkId) => {
    return dispatch => {
        dispatch(getStorySrcStart());
        let clientId = localStorage.getItem('client_id');
        axios.get(KIDS_BACK_PROD + '/' + IGP_CODE + '/audios/' + storyId + '?client_id=' + clientId +'&link_id='+linkId,
            {headers: {Accept: "application/vnd.gameloft.kids-v2.0.0+json"}}
        ).then(response => {
            logger.debug(response);
            dispatch(getStorySrcSuccess(response.data));
        }).catch(error => {
            logger.debug(error);
            dispatch(apiCallFailed(error.error));
        });
    };
};
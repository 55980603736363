import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    isPinVerified: false,
    kidsProfiles: null,
    createKidProfile: false,
    kidProfile: {
        id: '',
        name: '',
        age: '',
        avatarId: '',
        games: {
            allowed: "true",
            blocked: [],
            blocked_categories: [],
            favorites: []
        },
        videos: {
            allowed: "true",
            blocked: [],
            blocked_categories: [],
            favorites: []
        },
        comptines: {
            allowed: "true",
            blocked: [],
            blocked_categories: [],
            favorites: []
        },
        stories: {
            allowed: "true",
            blocked: [],
            blocked_categories: [],
            favorites: []
        },
    },
    updateProfileId: false,
    newKidProfileJustSaved: false,
    loading: false,
    profileWasUpdated: false,
    isTutorialSkipped: false,
    isAddKidsModalEnabled: false,
    isTipsModalEnabled: true,
    isPinVerificationFailed: false
};

const updateKidProfile = (state, action) => {
    const newProfile = {
        ...state.kidProfile,
        ...action.kidProfile,
        games: {
            ...state.kidProfile.games,
            ...action.kidProfile.games
        },
        videos: {
            ...state.kidProfile.videos,
            ...action.kidProfile.videos
        },
        comptines: {
            ...state.kidProfile.comptines,
            ...action.kidProfile.comptines
        },
        stories: {
            ...state.kidProfile.stories,
            ...action.kidProfile.stories
        },
    };
    return updateObject(state, {
        createKidProfile: false, loading: false, kidProfile: newProfile, updateProfileId: action.updateProfileId,
        profileWasUpdated: true
    })
};
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.KID_PROFILE_CREATE_START:
            //@TODO initialState.kidProfile already mutated in this point O_o https://jira.gameloft.org/browse/QA-2434
            return updateObject(state, {
                createKidProfile: true,
                loading: false,
                newKidProfileJustSaved: false,
                kidProfile: {
                    id: '',
                    name: '',
                    age: '',
                    avatarId: '',
                    games: {
                        allowed: "true",
                        blocked: [],
                        blocked_categories: [],
                        favorites: []
                    },
                    videos: {
                        allowed: "true",
                        blocked: [],
                        blocked_categories: [],
                        favorites: []
                    },
                    comptines: {
                        allowed: "true",
                        blocked: [],
                        blocked_categories: [],
                        favorites: []
                    },
                    stories: {
                        allowed: "true",
                        blocked: [],
                        blocked_categories: [],
                        favorites: []
                    },
                },
                updateProfileId: false,
            });
        case actionTypes.KID_PROFILE_CREATE_FAILED:
            return updateObject(state, {loading: false});
        case actionTypes.KID_PROFILE_CREATE_SUCCESS:
            return updateObject(state, {createKidProfile: false, loading: false});
        case actionTypes.KID_PROFILE_CREATE_UPDATE:
            return updateKidProfile(state, action);
        case actionTypes.KID_PROFILE_EDIT:
            return updateObject(state, {
                createKidProfile: false,
                loading: false,
                kidProfile: action.kidProfile,
                updateProfileId: action.updateProfileId,
                newKidProfileJustSaved: false
            });

        case actionTypes.SAVE_KIDS_PROFILE_START:
            return updateObject(state, {
                loading: true,
                kidsProfiles: action.kidsProfiles
            });
        case actionTypes.SAVE_KIDS_PROFILE_SUCCESS:
            return updateObject(state, {
                kidsProfiles: action.kidsProfiles,
                loading: false,
               // kidProfile: initialState.kidProfile,
                newKidProfileJustSaved: action.newProfile,
                updateProfileId: false,
            });
        case actionTypes.SAVE_KIDS_PROFILE_FAILED:
            return updateObject(state, {loading: false});

        case actionTypes.UPDATE_PROFILE_EDIT_CANCEL:
            return updateObject(state, {
                updateProfileId: false,
                kidProfile: initialState.kidProfile,
                newKidProfileJustSaved: false
            });

        case actionTypes.PIN_VERIFICATION_SUCCESS:
            return updateObject(state, {isPinVerified: true, isPinVerificationFailed: false});
        case actionTypes.PIN_VERIFICATION_FAILED:
            return updateObject(state, {isPinVerified: false, isPinVerificationFailed: true});
        case actionTypes.PIN_VERIFICATION_CLEAR:
            return updateObject(state, {isPinVerified: false, isPinVerificationFailed: false});

        case actionTypes.MODAL_MODE_DEFAULT:
            return updateObject(state, {isAddKidsModalEnabled: false, isTipsModalEnabled: true});
        case actionTypes.MODAL_MODE_NEW_USER:
            return updateObject(state, {isAddKidsModalEnabled: true, isTipsModalEnabled: true});
        case actionTypes.MODAL_MODE_NONE:
            return updateObject(state, {isAddKidsModalEnabled: false, isTipsModalEnabled: false});

        case actionTypes.SKIP_TUTORIAL :
            return updateObject(state, {isTutorialSkipped: true});
        default:
            return state;
    }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    games: [],
    videos: [],
    stories: [],
    favorites: [],
    features: [],
    videoList: [],
    storyList: [],
    subsections: [],
    kidProfile: null,
    loading: false,
    error: null,
    videoSrc: null,
    flashMessage: true,
    gameLink: null
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.API_CALL_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_KID_PROFILE_SUCCESS:
            return updateObject(state, {kidProfile: action.kidProfile, loading: false});
        case actionTypes.API_CALL_FAILED:
            return updateObject(state,{ error: action.error, loading: false });
        case actionTypes.GET_GAMES_SUCCESS:
            return updateObject(state,{ games: action.games, loading: false });
        case actionTypes.GET_VIDEOS_SUCCESS:
            return updateObject(state,{ videos: action.videos, loading: false });
        case actionTypes.GET_VIDEO_SRC_START:
            return updateObject(state, {loading: true, videoSrc: null});
        case actionTypes.GET_VIDEO_SRC_SUCCESS:
            return updateObject(state,{ videoSrc: action.videoSrc, loading: false });
        case actionTypes.GET_STORIES_SUCCESS:
            return updateObject(state,{ stories: action.stories, loading: false });
        case actionTypes.GET_STORY_SRC_START:
            return updateObject(state, {loading: true, storySrc: null});
        case actionTypes.GET_STORY_SRC_SUCCESS:
            return updateObject(state,{ storySrc: action.storySrc, loading: false });
        case actionTypes.GET_PLAY_LINK_START:
            return updateObject(state,{ gameLink: null, loading: true });
        case actionTypes.GET_PLAY_LINK_SUCCESS:
            return updateObject(state,{ gameLink: action.gameLink, loading: false });
        case actionTypes.GET_FEATURES_SUCCESS:
            return updateObject(state,{ features: action.features, loading: false });
        case actionTypes.GET_SUBSECTION_START:
            return updateObject(state,{ loading: true });
        case actionTypes.GET_SUBSECTION_SUCCESS:
            return updateObject(state,{ subsections: [...state.subsections, action.subsection], loading: false });
        case actionTypes.GET_VIDEO_LIST_SUCCESS:
            return updateObject(state,{ videoList: action.videoList, loading: false });
        case actionTypes.GET_STORIES_LIST_SUCCESS:
            return updateObject(state,{ storyList: action.storyList, loading: false });
        case actionTypes.FLASH_MESSAGE_RESET:
            return updateObject(state,{ flashMessage: true });
        case actionTypes.FLASH_MESSAGE_SHOWN:
            return updateObject(state,{ flashMessage: false });
        default: return state;
    }
};

export default reducer;